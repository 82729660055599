import React, { useState, useEffect } from "react";

const ImageLoadingAnimation = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 50);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <div
      style={{
        overflow: "hidden",
        transition: "transform 1.5s ease-out",
        transform: loading ? "scale(1)" : "scale(1.2)",
        width: "400px",
      }}
    >
      <img
        src="/goaLogoColor.png"
        alt="YourImage"
        style={{
          width: "100%",
          height: "auto",
          opacity: loading ? 0 : 1,
          transition: "opacity 0.5s ease-out",
        }}
      />
    </div>
  );
};

export default ImageLoadingAnimation;
