import "./App.css";
import Loader from "./Loader";

function App() {
  return (
    <div className="App">
      <Loader />
    </div>
  );
}

export default App;
