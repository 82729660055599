import "./Home.scss";
import { IoIosCall } from "react-icons/io";
import { IoMdSend } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaGlobe } from "react-icons/fa6";
import { FaUserPlus } from "react-icons/fa6";
import { useState } from "react";
import { MdSaveAlt } from "react-icons/md";

const HomeComponent = () => {
  const [active, setActive] = useState(false);
  const handleSaveClick = () => {
    // Get the contact information
    const contact = {
      name: "Goa Celebrations",
      phone: "9764506868",
      email: "brian@goacelebrations.com",
    };
    // var textFile: any = null;
    // Create a vCard file
    const vcard = `BEGIN:VCARD\nVERSION:3.0\nFN:${contact.name}\nTEL;TYPE=work,voice:${contact.phone}\nEMAIL:${contact.email}\nEND:VCARD`;
    // Create a Blob from the vCard data
    const blob = new Blob([vcard], { type: "text/vcard" });
    // Create a URL for the Blob
    // if (textFile !== null) {
    //   window.URL.revokeObjectURL(textFile);
    // }
    var url = URL.createObjectURL(blob);

    // textFile = URL.createObjectURL(blob);
    console.log(url);
    // Create a link element and trigger a click to download the file
    const newLink = document.createElement("a");
    newLink.download = `${contact.name}.vcf`;
    newLink.textContent = contact.name;
    newLink.href = url;
    newLink.click();
    // setTimeout(function () {
    // document.body.removeChild(newLink);
    URL.revokeObjectURL(url);
    // }, 100);
    // document.body.removeChild(newLink);
    // window.URL.revokeObjectURL(textFile);
  };
  return (
    <div className="home">
      <header className="header">
        <div className="headerWrapper">
          <div className="mainHeader">
            <img className="comLogo" src="/goaLogoWhite.png" alt="companyLogo" />
            <h2>Goa Celebrations</h2>
          </div>
          <div className="headerBtn">
            <a className="numLink" href="tel:+919764506868" target="_blank">
              <div className="callBtn">
                <IoIosCall className="callIcon" size={"18px"} />
                <p className="paraText">call</p>
              </div>
            </a>
            <a className="mailLink" href="mailto:brian@goacelebrations.com" target="_blank">
              <div className="mailBtn">
                <IoMdSend className="emailIcon" />
                <p className="paraText">email</p>
              </div>
            </a>
          </div>
        </div>
      </header>
      <section className="cardContainer">
        <div className="card">
          <div className="vCardProfile">
            <div className="numberImg">
              <IoIosCall size={"26px"} color="#b3b4bb" className="numberIcon" />
            </div>
            <div className="number">
              <a className="numberLink" href="tel:+919764506868" target="_blank">
                +91 97645 06868
              </a>
              <p className="numberText">Mobile</p>
            </div>
          </div>
          <div className="vCardProfile">
            <div className="numberImg">
              <MdEmail size={"26px"} color="#b3b4bb" className="numberIcon" />
            </div>
            <div className="number">
              <a className="numberLink" href="mailto:brian@goacelebrations.com" target="_blank">
                brian@goacelebrations.com
              </a>
              <p className="numberText">Email</p>
            </div>
          </div>
          <div className="vCardProfile">
            <div className="numberImg">
              <FaGlobe size={"26px"} color="#b3b4bb" className="numberIcon" />
            </div>
            <div className="number">
              <a className="numberLink" href="https://www.goacelebrations.com/" target="_blank">
                www.goacelebrations.com
              </a>
              <p className="numberText">Website</p>
            </div>
          </div>
          <div className="vCardProfile">
            <button id="save-btn" className="saveBtn" onClick={() => setActive(!active)}>
              <FaUserPlus size={"26px"} color="#fff" className="numberIcon" />
              save contact
            </button>
            {active && (
              <div className="saveContactModal">
                <div className="backDrop" onClick={() => setActive(false)}></div>
                <div className="modalBox">
                  <div className="CloseBtn" onClick={() => setActive(false)}>
                    X
                  </div>
                  <div className="modalHeader">Save Contact Data</div>
                  <div className="modalSubHeader">How would you like to save contact data?</div>
                  <ul className="contactAction">
                    <li className="addContactAction" onClick={handleSaveClick}>
                      <MdSaveAlt size={"26px"} color="rgba(0,0,0,0.87)" className="callIcon" />
                      <span className="modalText">Save to Phone Number</span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeComponent;
